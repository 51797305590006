/*
*  Author: Marton Szeles
*  Copyright 2014
*/

@font-face {
  font-family: 'Livvic'; 
  src: url('./fonts/Livvic/Livvic-Regular.ttf');
}

@font-face {
  font-family: 'LexendExa'; 
  src: url('./fonts/Lexend_Exa/LexendExa-Regular.ttf');
}

/* For small mobile phones: */
@media only screen and (max-width: 500px) {
  .project-text{
      -webkit-column-count: 1 !important; /* Old Chrome, Safari and Opera */
      -moz-column-count: 1 !important; /* Old Firefox */
      column-count: 1 !important;
  }
}

/* For mobile phones: */
@media only screen and (max-width: 768px) {
  p{
      font-size: 16px;
  }
  h2{
      margin-top: 10px;
      font-size: 18px;
  }
  h3{
      font-size: 16px;
  }
  .hide-on-mobile{
      display: none;
  }
  .page-logo{
      font-size: 20px;
  }
  .dashboard{
      font-size: 30px;
      line-height: 40px;
      width: 100%;
  }
  .home-description p{
      font-size: 20px !important;
  }
  .horizontal-menu ul li a {
      font-size: 20px !important;
  }
  .horizontal-menu {
      bottom: 100px !important;
  }
  .dashboard ul{
      display: none;
  }
  .mobile-menu{
      margin-top: 25%;
      padding-top: 10%;
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      background: #c90001;
      z-index: 999;
  }
  .dashboard .mobile-menu ul{
      display: inline-block;
      width: 98%;
      overflow: hidden;
      text-align: center;
      padding: 5px;
  }
  .float-left{
      float: none !important;
  }
  
  .float-right{
      float: none !important;
  }
  .short_intro{
      margin-bottom: 20px;
  }
  .project-title{
      text-align: center;
      margin-top: 10px !important;
      margin-bottom: 10px !important;
  }
  .bio{
      padding-top: 25px !important;
      width: 90% !important;
  }
  .album_frame img{
      width: 260px;
  }
  .logo-link{
      width: 180px !important;
      height: 60px !important;
      background-size: contain;
  }
  .half{
      width: 100% !important;
  }
  .work_title{
      height: 25px !important;
      background-size: auto 15px;
  }
}

/* For desktop: */ 
@media only screen and (min-width: 769px) {
  html{
      overflow-y: scroll !important;
  }
  .hide-on-desktop{
      display: none;
  }
  p{
      font-size: 21px;
  }
  .page-logo{
      font-size: 35px;
      line-height: 35px;
  }
  .menu-toggle{
      display: none;
  }
  .dashboard{
      font-size: 27px;
      line-height: 40px;
      min-height: 70px;
      width: 10%;
      min-width: 150px;
      float: right;
  }
  .short_intro{
      width: 45%;
      min-width: 600px;
      margin-bottom: 20px;
  }
  .bio{
      max-width: 1280px;
      width: 85%;
  }
  .bio_contacts{
      display: inline-block;
      width: 45%;
      min-width: 600px;
  }
  .bio_about{
      width: 52%;
      min-width: 600px;
  }
  .album_frame img{
      width: 410px;
  }
}

html{
  max-width: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 100%;
}

body{
  margin: 0;
  font-family: Livvic;
  max-width: 100%;
  height: 100%;
  background: white;
  color: black;
}

* {
  margin: 0;
  padding: 0;
}

.removed{
  display: none;
}

.main_container{
  height: 100%;
}

.home_container{
  height: 100%;
}

.right{
  text-align: right !important;
}

.left{
  text-align: left !important;
}

.center{
  text-align: center !important;
}

.float-left{
  float: left;
}

.float-right{
  float: right;
}

.half{
  width: 48%;
  display: inline-block;
}

.divide-title{
  background: #c90001;
  display: inline-block;
  height: 50px;
  width: 100%;
  color: black;
  line-height: 50px;
  font-size: 22px;
  font-weight: bold;
  margin-top: 60px;
}

.home-description:before{
  content: "";
  display: inline-block;
  height: 50%;
  vertical-align: middle;
}

.home-description{
  height: 60%;
  margin-left: 20px;
  margin-right: 20px;
}

.home-description p{
  font-size: 30px;
  color: white;
}

.horizontal-menu{
  font-family: LexendExa;
  bottom: 50px;
  left: 0;
  position: absolute;
  width: 100%;
}

.horizontal-menu ul {
  width: 100%;
  margin: 1px auto 0 auto;
  height: 50px;
  padding: 0;
  display: table;
  text-align: center;
}
.horizontal-menu ul li {
  list-style-type: none;
  display: table-cell;
  vertical-align: middle;
}
.horizontal-menu ul li a {
  font-size: 45px;
  color: white;
  display: block;
}

.zeroOpacity {
    opacity: 0 !important;
    transition: opacity 500ms ease-in;
}

.fullOpacity {
    opacity: 1;
    transition: opacity 500ms ease-out;
}

p a:hover{
  color: black;
}
a{
  color:rgb(53, 53, 53);
  text-decoration: none;
  cursor: pointer;
}

a:visited{
  color:rgb(53, 53, 53);
  text-decoration: none;
}

p{
  color: black;
}

.bio p{
  color: #1b1b1b;
}

h1{
  font-size: 23px;
  text-align: left;
  color: black;
  font-weight: bold;
}

h2{
  text-align: left;
}

h4{
  font-size: 40px;
}

/* TODO: this needs to be sorted out in a nicer way */
.vertical-middle{
  margin-top: 10%;
}

hr{
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid rgb(230, 230, 230);
  margin-left:20px;
  margin-right:20px;
}

.logo-link{
  display: inline-block;
  width: 350px;
  height: 117px;
  background-image: url("./images/mirador_text_logo.png");
}

.content .page-logo{
  padding: 3%;
}

.page-logo{  
  letter-spacing: 0px;
  vertical-align: top;
  user-select: none;
  text-align: center;
}

.menu{
  overflow: auto;
  z-index: 1;
  background: #c90001;
}

.menu_content{
  margin: 3%;
  position: relative;
}

.menu-toggle{
  position: absolute;
  top: 0;
  right: 3%;
  float: right;
  height: 35px;
  width: 25px;
  font-size: 30px;
  text-align: center;
  vertical-align: middle;
}

.menu-toggle div{
  text-align: center;
}

.bar1, .bar2, .bar3 {
  width: 25px;
  height: 3px;
  background-color: black;
  margin: 6px 0;
  transition: 0.4s;
}

.toggled .bar1 {
  -webkit-transform: rotate(-45deg) translate(-7.5px, 6px);
  transform: rotate(-45deg) translate(-7.5px, 6px);
}

.toggled .bar2 {
  opacity: 0;
}

.toggled .bar3 {
  -webkit-transform: rotate(45deg) translate(-6px, -6px);
  transform: rotate(45deg) translate(-6px, -6px);
}

.icon {
  display: none;
}

.dashboard{
  text-align: left;
  overflow-x: hidden;
  vertical-align: top;
  position: absolute;
  top: 0;
  right: 0px;
  font-family: LexendExa;
}

.dashboard a{
  color: black;
}

.bright_dashboard a{
  color: white !important;
}

.dashboard li{
  list-style-type: none;
}

.active{
  text-decoration: underline;
  color: black !important;
}

.dash_line{
  height: 8px;
  background: rgb(60, 60, 60);
}

.content{
  position: relative;
  z-index: 0;
  margin: auto;
  text-align: center;
}

.red{
  background: #c90001;
}

.shadow{
  box-shadow: 0 2px 12px rgba(14, 14, 14, 0.5);
}

.copyright{
  font-size:11px;
  color:rgba(70, 70, 70, 0.6);
  margin:10px auto;
  padding-right:10px;
  text-align: right;
  width:1024px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fill-width{
  width: 100%;
}

.fill-page{
  height: 100vh;
  padding: 0 !important;
  min-height: 625px;
}

/* Home Video */
.video-background{
  /* background-size: cover; */
  position: fixed;
  min-width: 100%; 
  min-height: 100%;
  background-color: white;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
  pointer-events: none;
}

.video-background::-webkit-media-controls-fullscreen-button
{
  display: none !important;
}

.wallpaper-background{
  background-size: cover;
  position: fixed;
  min-width: 100%; 
  min-height: 100%;
  background-image: url('./images/mirador_main.jpg');
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: -100;
}

/*Home Works*/
.work_item{
  background-color: #c90001;
  position: relative;
}

.work_title{
  height: 60px;;
  color: black;
  font-size: 28px;
  background-repeat: repeat-x;
  -webkit-animation: marquee 5000s linear infinite;
  background-position: center;
  animation: marquee 5000s infinite linear;
}

@keyframes marquee {
  0% {
    background-position: 0;
  }

  100% {
    background-position: -100000px;
  }
}

@-webkit-keyframes marquee {
  0% {
    background-position: 0;
  }

  100% {
    background-position: -100000px;
  }
}

.work_title_text{
  overflow: hidden;
  position: absolute;
  will-change: left;
}

.work_image img{
  width: 100%;
  display: block;
}


/*Biography*/
.bio{
  padding-top: 30px;
  text-align: left;
  display: inline-block;
}

.bio a{
  color: rgba(0, 0, 0, 0.78);
  text-decoration: underline;
}

.bio_contacts{
  margin-bottom: 20px;
  vertical-align: top;
  text-align: left;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.bio_contact{
  margin-bottom: 20px;
}

.bio_about{
  display: inline-block;
  text-align:left;
  font-size: 16px;
}

.about_title{
  margin-bottom: 10px;
}

.about_long{
  position: relative;
  margin: 0 0 30px 0;
}

.about_row{
  margin-bottom: 10px;
}

.about_row .row_title{
  display: inline-block;
  width: 30%;
}

.about_row .row_value{
  display: inline-block;
  width: 64%;
}

/*Projects*/
.project-cover{
  width: 100%;
  display: block;
}

.album_frame{
  margin: 10px;
  position: relative;
  display: inline-block;
  cursor: pointer;
  -webkit-transition: background-color 25ms linear;
  -moz-transition: background-color 25ms linear;
  -o-transition: background-color 25ms linear;
  -ms-transition: background-color 25ms linear;
  transition: background-color 25ms linear;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: white;
}

.album_frame:hover .overlay {
  opacity: 0.9;
}

.overlay-text {
  font-weight: bold;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
}

.album_title{
  margin-top: 10px;
  font-weight: bold;
  color: black;
  text-decoration: none;
  text-align: left;
  -moz-transition: opacity .25s ease;
  -webkit-transition: opacity .25s ease;
  -ms-transition: opacity .25s ease;
  -o-transition: opacity .25s ease;
  transition: opacity .25s ease;
  font-size: 15px;
}

.project-title{
  line-height: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
  font-weight: bold;
  font-size: 22px;
}

.project-text{
  font-family: "Livvic";
  margin-top: 3%;
  margin-bottom: 4%;
  -webkit-column-count: 2; /* Old Chrome, Safari and Opera */
  -moz-column-count: 2; /* Old Firefox */
  column-count: 2;
  column-gap: 70px;
}

.project-video{
  width: 100%;
  max-width: 1280px;
  display: block;
  margin: auto;
}

.more-projects{
  margin-top: 9%;
}

/* GAME */
.logo, .progress {position: absolute; left: 50%; top: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%);}
.logo {background: url('./images/TemplateData/progressLogo.Dark.png') no-repeat center / contain; width: 154px; height: 130px;}
.progress {height: 18px; width: 141px; margin-top: 110px; border: 1px white solid;}
.progress .empty {background: url('./images/TemplateData/progressEmpty.Dark.png') no-repeat left / cover; float: right; width: 100%; height: 100%; display: block;}
.progress .full {background: url('./images/TemplateData/progressFull.Dark.png') no-repeat right / cover; float: left; width: 0%; height: 100%; display: block;}

.logo.Dark {background-image: url('./images/TemplateData/progressLogo.Dark.png');}
canvas{ display: block; }